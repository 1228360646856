.faq-section {
  padding: 5px;
}

.tokenization-section {
  background-color: #041e37;
  color: #fff !important;
  padding: 2rem 0;
}

.tokenization-section {
  padding: 80px 0;
}

.section-titlet {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section-description {
  font-size: 18px;
  color: #777;
  margin-bottom: 40px;
}

.content-block {
  margin-bottom: 40px;
}

.content-icon {
  font-size: 30px;
  color: #555;
  margin-bottom: 20px;
}

.content-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.content-description {
  font-size: 16px;
  color: #777;
}

.rounded-image {
  border-radius: 50%;
  overflow: hidden;
}

.rounded-image img {
  width: 100%;
  height: auto;
}

.ct-right {
  text-align: right;
}
