.section-wrapper {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.section-head {
  margin-bottom: 40px;
}

.section-title {
  font-size: 2rem;
  color: #333;
}

.partner-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.partner-logo {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
