.disclaimer-section {
  padding: 20px 0;
  background-color: #f8f9fa;
  text-align: left;
  border-top: 1px solid #e9ecef;
}

.disclaimer-text {
  font-size: 0.9rem;
  color: #6c757d;
  margin: 0;
}
