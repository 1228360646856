.section-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-head .title {
  color: #362465;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
}

.section-head p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

.icon-bx-wraper {
  background-color: #362465;
  border-radius: 10px;
  box-shadow: 0 25px 50px rgba(28, 46, 158, 0.3);
  display: flex;
  height: 100%;
  padding: 32px 30px;
  transition: all 0.8s;
  display: flex;
  align-items: center;
}

.icon-bx-wraper:hover {
  transform: translateY(-2px);
}

.icon-bx-wraper .icon-media {
  height: 110px;
  margin-right: 20px;
  min-width: 110px;
  width: 110px;
}

.icon-bx-wraper .icon-media img {
  height: 100%;
  width: 100%;
}

.icon-bx-wraper .title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
}

.icon-bx-wraper .icon-content p {
  color: #c5cdff;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 0;
  transition: all 0.8s;
}

.more-btn {
  font-size: 15px !important;
  padding: 10px 40px !important;
  display: inline-flex !important;
  background-color: #679d4f !important;
  color: #fff !important;
  border-color: #679d4f !important;
  background: linear-gradient(to right, #679d4f, #507e3f);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.gstarted-wrapper-box {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 30px 45px rgba(28, 46, 158, 0.15);
  height: 100%;
  margin-left: -65px;
  margin-right: -65px;
  padding: 40px 50px;
  position: static;
}

.gstarted-box {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.gstarted-box .icon {
  width: 107px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  font-size: 28px;
  color: #ff9700;
  position: relative;
  border: 1px solid hsla(0, 0%, 100%, 0.07);
  z-index: 1;
  margin: 0 auto 10px;
}

.gstarted-box .icon .icon-background {
  position: absolute;
  width: 83px;
  height: 83px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0b1d33;
  border-radius: 50%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .section-head p {
    font-size: 16px;
  }
  .icon-bx-wraper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon-content {
    margin-top: 15px;
    text-align: center;
  }
  .gstarted-wrapper-box.style-1 {
    padding: 30px;
  }
  .gstarted-wrapper-box {
    height: auto;
    margin-left: 0;
    margin-right: 0;
    padding: 23px;
  }

  .section-head .title {
    font-size: 35px;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .gstarted-wrapper-box {
    margin-left: 0;
    margin-right: 0;
  }
}
