.nav-header {
  display: inline-block;
  height: 5rem;
  position: absolute;
  text-align: left;
  top: 0;
  transition: all 0.2s ease;
  width: 15.75rem;
  z-index: 5;
  border-top-left-radius: 0;
  border-top-right-radius: 2.5rem;
}

.nav-header .brand-logo {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 1.125rem;
  font-weight: 700;
  height: 100%;
  justify-content: flex-start;
  padding-left: 2.9rem;
  padding-right: 3rem;
  text-decoration: none;
  width: 100%;
}

.nav-header .logo-abbr {
  width: 57px;
  height: 57px;
}

.navbar {
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  height: 5rem;
  padding: 0px 43px 0 9.563rem;
  position: relative;
  transition: all 0.2s ease;
  z-index: 1;
  z-index: 3;
}

.header .header-content {
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 7rem;
  padding-right: 1.075rem;
  position: relative;
}

.header-left .dashboard_bar {
  color: #362465;
  font-size: 1.75rem;
  font-weight: 600;
}

.header-right .header-profile img {
  width: 45px;
}

.header-profile img {
  border: 3px solid #9568ff;
  border-radius: 0.938rem;
}

.sidebar-main {
  box-shadow: 0 0.9375rem 1.875rem 0 rgba(0, 0, 0, 0.02);
  height: calc(100% - 5rem);
  padding-bottom: 10px;
  padding-top: 1rem;
  position: absolute;
  top: 5rem;
  transition: all 0.2s ease;
  width: 15.75rem;
  z-index: 6;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 1.25rem;
}

.sidebar-main .menu-list {
  display: flex;
  flex-direction: column;
}

.sidebar-main ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-main .menu-list > li > a {
  border-radius: 0.625rem;
  font-size: 1rem;
  margin: 5px 0;
  padding: 0.75rem 0.5rem;
  transition: all 0.5s;
  color: #362465;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.sidebar-main .menu-list > li > a:hover {
  background: #362465;
  color: #fff;
}

.sidebar-main .menu-list a {
  color: #362465;
  display: block;
  outline-width: 0;
  padding: 0.625rem 1.875rem;
  position: relative;
  text-decoration: none;
}

.sidebar-main .menu-list > li {
  padding: 0 1.5rem;
}

.sidebar-main .menu-list > li a > .icon {
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  border-radius: 0.125rem;
  border-radius: 0.625rem;
  display: inline-block;
  font-family: Material Icons;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: 1;
  margin-right: 0.65rem;
  position: relative;
  text-align: center;
  top: 0;
  vertical-align: middle;
  width: auto;
}

.sidebar-main .menu-list ul {
  padding: 0.5rem 0;
  position: relative;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.sidebar-main .menu-list .active {
  background: #362465;
  color: #fff;
}

.balance-card .coin-card {
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: row;
}

.coin-card .back-image {
  position: absolute;
  right: 0;
  z-index: -1;
}

.balance-card .coin-card .title h4 {
  color: #362465;
  font-size: 1.125rem;
  font-weight: 600;
}

.balance-card .coin-card .chart-num {
  color: #362465;
}

.main-dashboard {
  padding-left: 16rem;
  padding-right: 1.5rem;
}

.dasboard-btns {
  display: flex;
  justify-content: space-around;
  padding: 50px 30px;
}

.referral-section {
  margin-top: 20px;
}

.upline {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.upline-icon {
  font-size: 48px;
  color: #333;
}

.slot {
  margin-bottom: 20px;
  position: relative; /* Added for positioning the line */
}

.locked-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.unlocked-slot {
  height: 60px;
  background: rgb(63, 201, 63);
  box-shadow: 0 0.9375rem 1.875rem 0 rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.unlocked-slot .earning-icon {
  color: #fff;
  font-size: 24px;
}

.p-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 15px 40px rgba(1, 18, 111, 0.15);
  overflow: hidden;
  padding: 20px 25px;
  position: relative;
  transition: all 0.8s;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
  border: none !important;
}

.copy-icon {
  cursor: pointer;
}

.earnings .section-title {
  display: flex;
  justify-content: space-between;
}

.earnings-pills {
  border: 1px solid #9568ff !important;
  border-radius: 0.5rem;
  padding: 6px;
  padding-left: 5px !important;
  cursor: pointer;
}

.earning-slots {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.earning-slots .slot {
  width: 49px;
  height: 49px;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0px 0px 0 3.563rem;
  }
  .nav-header {
    width: 9.75rem;
    background-color: #fff;
  }

  .sidebar-main {
    width: 15.75rem;
  }

  .sidebar-main .menu-list > li {
    padding: 0;
  }
  .header-left .dashboard_bar {
    font-size: 1.15rem;
  }

  .main-dashboard {
    padding: 0 1rem;
  }

  .p-card {
    padding: 0;
  }
  .p-card .user-info span {
    font-size: 15px;
  }

  .earnings .section-title {
    flex-direction: column;
  }

  .earnings-pills {
    margin-top: 1rem;
  }
}
