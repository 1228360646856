.navbar-bottom .social-icons {
  padding-bottom: 20px;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-bottom .social-icons a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 4px 6px;
  text-align: center;
}
