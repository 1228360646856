.about-img {
  position: relative;
  padding-left: 25px;
}

.about-img .about-left-image {
  position: absolute;
  width: 63px;
  left: 15px;
  top: -25px;
  animation: topRightImageAnimation 4s infinite;
}

@keyframes topRightImageAnimation {
  0% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }
  50% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }
  100% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }
}
