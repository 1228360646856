.mining-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.circle-wrapper {
  position: relative;
  width: 250px;
  height: 250px;
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f1f1f1;
  overflow: hidden;
}

.circle-border {
  position: relative;
  width: 100%;
  height: 100%;
  border: 20px solid #f1f1f1;
  border-radius: 50%;
  animation: circle-border-color 5s linear infinite;
}

@keyframes circle-border-color {
  0% {
    border-color: #f1f1f1;
  }
  25% {
    border-color: #679d4f;
  }
  50% {
    border-color: #8973bb;
  }
  75% {
    border-color: #6a53a1;
  }
  100% {
    border-color: #513b88;
  }
}

.circle-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-content-top,
.circle-content-bottom {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.circle-content-top img {
    width: 26px;
    margin-bottom: 1rem;
}

.circle-content-top h3,
.circle-content-bottom h3 {
  margin: 0;
  color: #333;
}

.circle-content-top p,
.circle-content-bottom p {
  margin: 0;
  color: #777;
}

.miners-section {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
}

.miners-info,
.referrals-info {
  text-align: center;
}

.miners-info h3,
.referrals-info h3 {
  margin: 0;
  color: #333;
  font-size: 14px;
}

.miners-info p,
.referrals-info p {
  margin: 0;
  color: #777;
}

.mining-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.mining-page .card {
  background-color: #fff;
  border: 0 solid transparent;
  border-radius: 1.25rem;
  box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
  height: 81vh;
  margin: 25px;
  margin-bottom: 1.875rem;
  position: relative;
  transition: all 0.5s ease-in-out;
  width: 550px;
}

@media screen and (max-width: 768px) {
    .mining-page .card {
        width: 376px;
    }
}
