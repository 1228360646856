.hero {
  background-image: url(../../assets/images/bg/mcgp-bg.jpg);
  background-position: 50%;
  background-size: cover;
  display: flex;
  height: 920px;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-bottom: 165px;
  flex-direction: column;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-title .sub-title {
  font-size: 1.125rem;
  line-height: 1.5;
}

.top-right-image {
  -webkit-animation: topRightImageAnimation 4s infinite;
  animation: topRightImageAnimation 4s infinite;
  position: absolute;
  right: -33px;
  top: 43px;
  width: 350px;
  opacity: 0.6;
}

.top-left-image {
  -webkit-animation: topRightImageAnimation 4s infinite;
  animation: topRightImageAnimation 4s infinite;
  position: absolute;
  left: -33px;
  top: 43px;
  width: 250px;
}

.bottom-left-image {
  animation: bottomLeftImageAnimation 4s infinite;
  animation-delay: 1s;
  bottom: 2px;
  left: -10px;
  position: absolute;
  width: 350px;
  opacity: 0.6;
}

.bottom-right-image {
  animation: bottomLeftImageAnimation 4s infinite;
  animation-delay: 1s;
  bottom: 2px;
  right: -10px;
  position: absolute;
  width: 350px;
  opacity: 0.6;
}

@keyframes topRightImageAnimation {
  0% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }
  50% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }
  100% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }
}

@keyframes bottomLeftImageAnimation {
  0% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }
  50% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }
  100% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }
}

.main-title {
  color: #fff;
  font-size: 45px;
  line-height: 1.2;
  margin-bottom: 32px;
  position: relative;
  text-transform: uppercase;
}

.content-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-title p {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.7;
  margin-bottom: 70px;
  color: #ffffff;
}

.hero-btn {
  padding: 14px 29px !important;
  font-size: 18px !important;
  background-color: #E8A14A !important;
  color: #ffffff !important;
  border-color: #E8A14A !important;
}

.hero-btn:hover {
  background: transparent !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-title {
    font-size: 63px;
  }
  .content-title p {
    font-size: 21px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .main-title {
    font-size: 22px;
  }
  .content-title p {
    font-size: 18px;
    font-weight: 400;
  }
  .top-right-image,
  .top-left-image,
  .bottom-left-image,
  .bottom-right-image {
    display: none;
  }
  .hero {
    height: 700px;
    padding-bottom: 0;
  }
}

.paper-btn {
  color: #ffffff !important;
  padding: 15px !important;
  margin-left: 25px !important;
}

.dropdown-link {
  color: #362465;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
}
